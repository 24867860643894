.navbar li .glyphicon { margin-right: 10px; }

/* Highlighting rules for nav menu items */

.navdropdown-pushleft .dropdown-menu {
    right: 0 !important;
    left: auto !important;
}

.navbar .dropdown-menu a {
    padding: 5px;
    margin: 0px;
}

.nav-dropdown-content {
    text-align: center;
}

.dropdown-menu.show a {
    color: #000 !important;
}

.navbar .navbar-nav .active a,
.navbar .navbar-nav .active a:hover,
.navbar .navbar-nav .active a:focus {
    background-color: #4189C7;
    background-image: none;
    color: white;
}

.SettingsDropDown{
    text-align: center;

}

.dropdown-menu.show a:hover {
    color: #4189C7 !important;
}

@media (min-width: 768px) {
    /* On large screens, convert the nav menu to a vertical sidebar */
    .navbar { height: 100%; }

    .navbar {
        border-radius: 0;
        border-width: 0;
        height: 100%;
    }

        .navbar .navbar-brand {
            font-size: 1.7rem;
            font-weight: bold;
        }

    .navbar-header { float: none; }

    .navbar .navbar-collapse {
        align-items: initial;
        flex-direction: column;
        padding: 0;
    }

    .section-start { border-left: 1px solid #555; }

    .navbar .container-fluid {
        margin: 0;
        padding: 0;
    }

    .navbar .container-fluid .navbar-brand { margin: 0; }

    .navbar ul { float: none; }

    .navbar-nav a {
        float: none;
        font-size: 15px;
        margin: 6px;
    }

    .nav-link {
        //border-radius: 4px;
        padding: 10px 16px;
    }

    .nav-link {
        text-overflow: ellipsis;
        white-space: nowrap;
        /* If a menu item's text is too long, truncate it */
        width: 100%;
    }
}

@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
  }
}


.NewActingPulse
{
  animation: shadow-pulse 1s infinite;
}