.settingsContainer {
    background-color: var(--container);
    margin-top: 25px;
    margin-bottom: 25px;
    border: solid 1px var(--border);
    border-radius: .25rem;
    box-shadow: 0 2px 4px 0 var(--borderShadow), 0 3px 10px 0 var(--borderShadow);

}
.settingsContainer h1 {
    padding: 15px;
}

.settingsOptionBlock {
    padding: 22px 5px;
    border-top: var(--container) solid 1px;
}

.settingsOptionBlock h5 span {
    padding-right: 10px;
}

.settingsSubtitle {
    font-size: 13px;
    padding-left: 30px;
}



.settingsActiveUsersHeading{ 
    text-align: center;
}

.settingActiveUsersBlock hr {
    width: 300px;
    color: var(--primary);
} 

.settingTimeZoneBlock {
    border-bottom: 1px solid var(--container);
    padding: 10px;
    font-weight: 700;
    text-align: center;
}

.settingTimeZoneBlock:hover {
    background-color:var(--primary);
    cursor: pointer;
}

.settingTimeZoneBlockActive, .settingTimeZoneBlockActive:hover {
    background-color: var(--primary)
}

.setting70Modal {
    width: 150px !important;
}

.settingSearchBar {
    padding: 0px
}



.AddTradingPartnerButton{
    border: 1px solid var(--success);
    border-radius: .25rem;
    padding: 4px;
    margin: 4px;
    color: var(--success);
    cursor: pointer;
    font-weight: 500;
  }

  .tradingPartnerList {
    padding: 4px
  }
  
  .tradingPartnerList:hover {
    cursor: pointer;
    color: var(--primary)
  }

  .tradingPartnerContainer {

    border-radius: .25rem;
    background-color: var(--container);
    border: solid 1px var(--border);
    border-radius: .25rem;
    box-shadow: 0 2px 4px 0 var(--borderShadow), 0 3px 10px 0 var(--borderShadow);
  }

  .tradingPartnerTitle {
    border-bottom: var(--background);
    text-align: center;
    font-weight: 500;
  }

  .tradingPartnerEditorContainer {
    border-radius: .25rem;
    background-color: var(--container);
    padding: 10px;
    border: solid 1px var(--border);
    border-radius: .25rem;
    box-shadow: 0 2px 4px 0 var(--borderShadow), 0 3px 10px 0 var(--borderShadow);
  }

  .adminsitrationBox {
      height: 170px;
      width: 170px;
      padding: 20px;
      border: solid 2px #000;
      cursor: pointer;
      border-radius: 0.25rem; 
      margin: 100px 10px 10px 10px;
      display:inline-block;
      line-height: 50px;
      text-align: center;
      vertical-align: bottom;   
  }

  .adminsitrationBox:hover {
    border: solid 2px #007bff;
}

.adminsitrationContainer {
    margin: 0 auto;
    text-align: center;
    width: 60%;
}

