@media (max-width: 767px) {
    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
    /*body {
        padding-top: 50px;
    }*/
}

.spinner-border {
    display: block;
    position: fixed;
    right: calc(50% - (28px / 2)); /* where ... is the element's width */
    top: calc(50% - (28px / 2)); /* where ... is the element's height */
    z-index: 1031;
}

.spinner-border-inline {
    display: block;
    position: absolute;
    right: calc(50% - (28px / 2)); /* where ... is the element's width */
     
    z-index: 1031;
}