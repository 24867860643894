$filter-font-size: 13px;

html {
  background: var(--background);

  --themeBtn: #007bff;
  --themeBg: #EBEBEB;
  --themeColor: #121212;
  --themeBlock: #FFF;
  --themeActiveBlock: #EEEEEE;
  --themeContainer: #F2F2F2;
  --themeBtn-Lighter: lighten(var(--themeBtn), 10%);
  --themeCloseBtn: #C91111;
  --themeApprovedBtn: #34A80D;
  --themebtnShadow: darken(var(--themeBtn), 30%);
  --themebtnHover: #ADD4FF;
  --themeCloseBtnDisabled: #c1a4a4;
  --themeApprovedBtnDisabled: #75a970;
  --themeBtnDisabled: #6986b0;
  --themeRefreshBtn: #3295FF;  

  --primary: #4c84ff;
  --warning: #F9A825;
  --success: #389466;
  --danger: #d24571;
  --container: #FAFAFA;
  --text: #19233c;
  --textBold: #19233c;
  --background: #ffff;
  --hover: #CBCEDA;
  --inputfield: #F1F1F1;
  --borderShadow:  rgba(0, 0, 0, 0.2);
  --border: #000;
  --filterShadow: rgba(0, 0, 0, 0.2);

}

html[data-theme='dark'] {
   background: var(--background);
  --themeBtn: #FFBD07;
  --themeBg: #121212;
  --themeColor: #fff;
  --themeBlock: #1D1D1D;
  --themeActiveBlock: #353535;
  --themeContainer: #3D3D3D;
  --themeBtn-Lighter: darken(#FFBD07, 10%);
  --themeCloseBtn: #C91111;
  --themeApprovedBtn: #34A80D;
  --themebtnShadow: darken(#FFBD07, 30%);
  --themebtnHover: #FFDD7F; 
  --themeCloseBtnDisabled: #c1a4a4;
  --themeApprovedBtnDisabled: #75a970;
  --themeBtnDisabled: #aca16c;
  --themeRefreshBtn: #3295FF;

  --primary: #4c84ff;
  --warning: #da8115;
  --success: #389466;
  --danger: #d24571;
  --dangerDisabled: #c1a4a4;
  --container: #252C48;
  --text: #a9abb5;
  --textBold: #c5ccde;
  --background: #171C32;
  --hover: #323a5d; 
  --inputfield: rgba(2555,255,255,0.1);
  --borderShadow:  rgba(0, 0, 0, 0);
  --border: rgba(0, 0, 0, 0);
  --filterShadow: rgba(255, 255, 255, 0.2);

}

h1,h2,h3,h4,h5,h6 {
  color: var(--textBold);
}

body, pre {
    color: var(--text);
    font-weight: 400;
}

h6:after {
    border-bottom: 1px solid var(--primary);
    content: '';
    display: block;
    width: 105%;
  }

  h6 {
    display: table;
  }

html.transition,
html.transition *,
html.transition *:before,
html.transition *:after {
  transition: cubic-bezier(0.68, -0.55, 0.27, 01.55) 420ms!important;
  transition-delay: 0!important;
}

.warningColor {
  color: var(--warning)
}


body {
    background-color: var(--background);
}

.row {
    margin-left: 0px;
    margin-right: 0px;
}
.container-fluid {
    padding-left: 0px;
    padding-right: 0px;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--text);
  font-weight: 400;
}


.navbar.navbar-light .navbar-nav .nav-item .nav-link {
  color: var(--text);
  font-weight: 400;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: var(--hover)
}

.navbar.navbar-light .navbar-nav .nav-item .nav-link:hover{
  color: var(--hover)
}

.navbar-light .navbar-nav .nav-link.active{
  color: var(--primary)
}

// Removes default Col Padding

.removeColPadding {
    padding: 0px;
}

.form-control, .form-control:focus {
    color: var(--text);
    background-color: var(--container)
}

table {
color: var(--text) !important;
}

.modal-content {
    background-color: var(--container);
    color: var(--text);
}

.PrimaryButton{
    background-color: var(--primary) !important;
}

.CloseButton {
    background-color: var(--danger) !important;
}

.table-striped tbody tr:hover {
  color: var(--hover)
}

table.table a {
  color: var(--primary);
}

.modalScroll {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number], input[type=text] {
  //background-color: var(--container);
  border: none;
  color: var(--text);
}

.inputCSS{
  border-bottom: solid 1px var(--primary);    
  color: #fff !important;
  background-color: var(--primary) !important;
  border: 1px solid #fff;
  border-radius: .25rem;
  margin-left: 3px;
}

.textinput {
  border-bottom: 1px solid var(--primary) !important;
  background-color: var(--inputfield);
}

input[type=number]:focus, input[type=text]:focus {
  outline-color: transparent;
  outline-style: none;
}
input {
  border-bottom: solid 1px var(--primary);
}

input[type=number]:disabled, input[type=text]:disabled {
  border: none;
  border-bottom: solid 1px var(--container);
}

.CreateButton {
  margin-left: auto;
  color: var(--success);
  background-color: transparent;
  border: 1px solid;
  padding: 0px 10px;
  border-radius: .5rem;
  cursor: pointer;
}
.CreateButton:hover {
  color: var(--text);
}

.EditButton {
  margin-left: auto;
  color: var(--primary);
  background-color: transparent;
  border: 1px solid;
  padding: 0px 10px;
  border-radius: .5rem;
  cursor: pointer;
}
.EditButton:hover {
  color: var(--text);
}

.DeleteButton {
  margin-left: auto;
  color: var(--danger);
  background-color: transparent;
  border: 1px solid;
  padding: 0px 10px;
  border-radius: .5rem;
  cursor: pointer;
}

.DeleteButton:hover {
  color: var(--text);
}

.CreateButton:disabled  {
  color: var(--themeApprovedBtnDisabled);
  opacity:0.6;
}

.EditButton:disabled, .EditButton.dropdown-toggle:disabled {
  color: var(--themeBtnDisabled); 
  opacity:0.6;
}

.DeleteButton:disabled {
  color: var(--themeCloseBtnDisabled); 
  opacity:0.6;
}

.RefreshButton  {
  margin-left: auto;
  color: var(--themeRefreshBtn);
  background-color: transparent;
  border: 1px solid;
  padding: 0px 10px;
  border-radius: .5rem;
  cursor: pointer;
}

.RefreshButton:hover{
  color: var(--text)
}

select {
  color: var(--text) !important;
}

svg {
    stroke: var(--text) !important;

    rect {
        stroke: #ffffff;
    }
}

/* width */
// ::-webkit-scrollbar {
//   width: 20px;
// }

/* Track */
// ::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 5px grey; 
//   border-radius: 10px;
//   background: var(--container); 
// }
 
/* Handle */
// ::-webkit-scrollbar-thumb {
//   background: var(--themeBtn); 
//   border-radius: 10px;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: var(--themebtnHover); 
// }

.spanIcon {
  background-color: var(--container) !important;
}

tbody + thead{
  display: none;
}

.invalid {
  width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}

.pagination .page-item .page-link {
  background-color: #fff;
  border: solid 1px #121212;
}

.grid-item-content {
  margin: 5px;
}

.deleteGridTab {
  background-color: var(--themeCloseBtn);
  color: #fff;
  padding: 4px;
  border-radius: 5px 5px 0px 0px;
}

.deleteGridTab:hover{
  cursor: pointer;
}

.menuLogOut, .menuLogOut:hover {
  color: #000 !important;
  cursor: pointer;
}