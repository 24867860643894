.timeHeader{
    border-radius: .25rem;
    //border: solid 2px var(--primary);
    padding: 20px 20px 10px 20px;
    margin: 20px 20px 10px 20px;
    background: #202746;
}

.timeContainer {
    border-radius: .25rem;
    //border: solid 2px var(--primary);
    padding: 20px;
    margin:  10px 20px 10px 20px;
    background: var(--container);
}

.selected {
    background-color: var(--themeActiveBlock);
}