

.filterContainer {

    border-radius: .25rem;
    background: var(--container);
    border: solid 1px var(--border);
    border-radius: .25rem;
    box-shadow: 0 2px 4px 0 var(--borderShadow), 0 3px 10px 0 var(--borderShadow);
}

.activefilter-clearall {
    padding: 10px 10px;
    text-decoration: underline;
    color: #fff
}


.activeFilter-header {
    color: var(--primary);
    padding: .75rem;
    font-weight: 800;
    font-size: 20px;
    text-align: center;
    border-bottom: 1px solid;
    border-radius: .25rem;
}

.filterTextBox {
    background-color: rgba(255, 255, 255,.3);
    padding: 5px 15px;
    margin: 10px;
    border-radius: .25rem;
}

.removeIcon {
    color: #d24571;
    padding-left: 10px;
}
//old filterstyle


// Filter Bar 

.filterBarContainer {
    //background-color:  var(--container);
    width: 100%;
    border-radius:.15rem;
    height: 100%;
    padding-right: 0px;
    padding-left: 0px;
}

.filterBarBlock {
    padding: 10px 0;
    border-bottom: solid 1px var(--filterShadow);
    margin: 0px 10px;
    cursor: pointer;
}

.filterBaLastBlock {
    padding: 10px 0;
    margin: 0px 10px;
    cursor: pointer;
}


.filterBarBlock:hover{
    cursor: pointer;
}

.filterDropDownBlock:hover{
    cursor: pointer;
}

.filterItem {
    font-size: $filter-font-size;
    padding: 5px 15px;
}

.filterDropDownBlock {
    padding: 10px 0;
    border-bottom: solid 1px rgba(255,255,255,0.2);
    margin: 0px 10px;
    cursor: pointer;
}

.filterDropdownActive {
    border-left: solid 4px #000;
    background-color: rgba(255, 255, 255,.3);
    padding: 10px 10px 10px 10px;
}


.activefilterheader {
    background-color: var(--primary);
    color: #fff;
    padding: .75rem;
    font-weight: 800;
    font-size: 20px;
    text-align: center;
    border-bottom: 1px solid;
    box-shadow: 0px 2px var(--primary);
}

.activefilterclearall {
    padding: 10px 10px;
    text-decoration: underline;
    color: var(--text)
}

.activeselectedfilterBox {
    color: var(--text);
    padding: 5px;
    border-radius: 0.50rem;
    font-weight: 500;
    font-size: .7vw;
}

.filterIcon {
    color: var(--text);
}

.activefiltercontainer {
border-bottom: 2px solid var(--container);
padding-bottom: 10px
}

.filtersubmitbutton {
    background-color: var(--primary);
    color: var(--text);
    border-radius: .25rem;
    margin: 0px 5px;

}

.CalendarModal {
    background: rgba(0,0,0,0.3);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2;
    height: 100%;
    width: 100%;
}

.CalendarBox {
    z-index: 3;
    position: relative;
    top: 5%;
    left: 40%;
}

.ModalCloseButton {
    color: #fff;
    margin: 3px;
    padding: 2px;
    font-size: 18px;
    border-radius: .5rem;
    border: solid 1px #000;
    background-color: #d24571;
}

.ModalCloseButton:hover {
    cursor: pointer;
}
//Hoverover

.onClickHoverOver:hover, .removeIcon:hover {
    cursor: pointer;
    color: #fd7c7c;
}

.subFilterDropDown {
    font-size: 13px;
    padding: 5px 5px;

}

.subFilterDropDownSpacer {
    border-bottom: solid 1px rgba(255, 255, 255, 0.2);
}