.switch {
    position: relative;
    display: inline-block;
    width: 90px;
    height: 34px;
  }
  
  .switch input {display:none;}
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ca2222;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .disabledslider{
    background-color: #c3bbbb !important;
  }
  
  input:checked + .slider {
    background-color: #2ab934;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(55px);
    -ms-transform: translateX(55px);
    transform: translateX(55px);
  }
  
  /*------ ADDED CSS ---------*/
  .on
  {
    display: none;
  }
  
  .on
  {
    color: white;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 35%;
    font-size: 10px;
    font-family: Verdana, sans-serif;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .off
  {
    color: white;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 60%;
    font-size: 10px;
    font-family: Verdana, sans-serif;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked+ .slider .on
  {display: block;}
  
  input:checked + .slider .off
  {display: none;}

  input:checked + .smallslider {
    background-color: #2ab934;
  }
  
  input:focus + .smallslider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  /*--------- END --------*/
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;}

/*------------------------------ Small toggle --------------------------*/


.smallswitch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 17px;
}

.smallswitch input {display:none;}

.smallslider label {
  margin-bottom: 0px !important;
}

.smallslider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ca2222;
  -webkit-transition: .4s;
  transition: .4s;
}

.smallslider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 1px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .smallslider:before {
  -webkit-transform: translateX(28px);
  -ms-transform: translateX(28px);
  transform: translateX(28px);
}

/*------ ADDED CSS ---------*/
.on
{
  display: none;
}

input:checked+ .smallslider .on
{display: block;}

input:checked + .smallslider .off
{display: none;}

/*--------- END --------*/

/* Rounded sliders */
.smallslider.round {
  border-radius: 12px;
}

.smallslider.round:before {
  border-radius: 50%;}

