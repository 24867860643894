.LiveExportBase {
  margin: 10px;
  border: solid 1px var(--text);
  border-radius: 0.25rem;
}

.LiveExportBaseHeader {
  background-color: var(--text);
  color: var(--container);
}

.LiveExportHeadingButtons {
  float: right;
  border: solid 1px var(--text);
  font-weight: 400;
  padding: 3px;
  font-size: 12px;
  margin: 5px;
  border-radius: 0.25rem;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}

.LiveExportRadioOptions,
.LiveExportRadioOptionsActive {
  float: "left";
  border: 1px solid;
  padding: 3px;
  margin: 5px;
  border-radius: 0.25rem;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}

.LiveExportRadioOptions:hover,
.LiveExportRadioOptionsActive:hover,
.LiveExportHeadingButtons:hover {
  cursor: pointer;
}

.LiveExportRadioOptionsActive {
  background-color: var(--success);
  color: #ffffff;
}

.react-daterange-picker {
  border-radius: 0.25rem;
}

.GridOptionNumberButton {
  display: inline-block;
  border: 1px solid var(--text);
  padding: 3px 8px;
  margin: 3px;
  border-radius: 0.25rem;
  cursor: pointer;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  /* Rules below not implemented in browsers yet */
  -o-user-select: none;
  user-select: none;
}

.GridOptionNumberButton:hover {
  cursor: pointer;
}

.removeReport {
  cursor: pointer;
  color: #dc3545;
  margin: 5px;
  float: right;
}
