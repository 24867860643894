.centerColumnContent {
    justify-content: center;
    display: flex;
}

.clickOnFunction {
    cursor: pointer;
}

.textOnClickFunction {
    color: var(--primary) !important;
    cursor: pointer;
}

.navbar.navbar-light .navbar-toggler {
    color: var(--text) !important;
}

.theadSticky, tbody .theadSticky {
    display: table;
    width: 100%;
}

.tbodySticky {
    display: block;
    height: 100%;
    max-height: 67vh;
    overflow: auto;
}

thead,
tbody tr td {
    text-align: center;
}

.table {
    margin-bottom: 0px;
}

.tableHeight table.table a.btn.btn-primary {
    height: 50%;
    width: 100%;
    font-size: 12px;
    padding: 11px;
}
.tableHeight table thead tr {
    padding-right: 10px;
}

.tableHeight table td {
    font-size: 13px;
}

.tableHeight {
    border: solid 1px #fff; //ToDO  use a var(---color)
    border-radius: 0.25rem;
    margin-top: 10px;
}

.informationIcon {
    color: var(--warning);
    margin-bottom: 2px;
}

.copied.copyFadeOut {
    opacity: 0;
}

.copied {
    color: var(--primary);
    text-align: center;
    -webkit-transition: opacity 5s ease-in-out;
    -moz-transition: opacity 5s ease-in-out;
    -ms-transition: opacity 5s ease-in-out;
    -o-transition: opacity 5s ease-in-out;
    opacity: 1;
    padding-left: 5px;
}

.copiedContainer {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    padding-bottom: 7px;
    padding-left: 10px;
}

.copyIcon {
    color: var(--primary) !important;
    cursor: pointer;
}

.headerTitle {
    font-size: 32px;
    font-weight: 700;
    display: inline-block;
}

.headerSubTitle {
    font-size: 17px;
    font-weight: 500;
    display: inline-block;
    margin-bottom: 6px;
}

.purchaseContainer {
    padding: 10px 20px 10px 20px;
}

.toolBlock {
    background-color: var(--container);
    border-radius: 0.5rem;
    height: 100%;
    border: solid 1px var(--border);
    border-radius: .25rem;
    box-shadow: 0 2px 4px 0 var(--borderShadow), 0 3px 10px 0 var(--borderShadow);
}

.detailBlock {
    background-color: var(--container);
    border-radius: 0.5rem;
    padding: 8px;
    width: 100%;
    height: 100%;
    border: solid 1px var(--border);
    border-radius: .25rem;
    box-shadow: 0 2px 4px 0 var(--borderShadow), 0 3px 10px 0 var(--borderShadow);
}

.detailContainer {
    font-size: 0.8rem;
    margin: 5px;
    padding: 3px;
    color: var(--text);
}

.detailRow {
    line-height: 1.1;
}

.ColumnPadding {
    padding: 5px !important;
}

.AcceptedOrderBanner {
    color: var(--success);
    font-weight: 600;
    padding-left: 15px;
    text-align: center;
}

.RejectedOrderBanner {
    color: var(--danger);
    font-weight: 600;
    padding-left: 15px;
    text-align: center;
}

.purchaseOrderNumberhighlight {
    color: var(--success);
}

.approvedBox {
    border: solid 1px var(--success);
    padding: 5px;
    border-radius: 0.25rem;
}

.rejectedBox {
    border: solid 1px var(--danger);
    padding: 5px;
    border-radius: 0.25rem;
}

.OptionIcon {
    border: solid 1px var(--text);
    height: 100%; 
    border-radius: .25rem; 
    text-align: center; 
    padding: 5px;
    cursor: pointer;
}

.OptionIcon:hover {
    background-color: var(--hover); 
}

.OptionInvoice {
    margin: 7px 0px 7px 0px;
}

.PSDButtonBlock {
    float: right;
  }
  
  .PSDButton {
    color: var(--primary);
  }
  
  .PSDButton:hover {
    cursor: pointer;
    color: var(--themebtnHover);
  }

  
.grid-item {
    border-radius: .25rem;
    background-color: var(--container);
    height: 100%;
    border: solid 1px var(--border);
    border-radius: .25rem;
    box-shadow: 0 2px 4px 0 var(--borderShadow), 0 3px 10px 0 var(--borderShadow);
  }


.orderOptions {
    padding: 10px 2px 10px 2px;
}

.freightChargeAllowance {
    border: solid 1px var(--themeColor);
    border-radius: 0.25rem;
}

.InvoiceTotal {
    float: right;
     padding: 30px;
     text-decoration: underline;
}

.iconFunctionButtons {
    cursor: pointer;
    margin: 0px 7px 0px 7px;
}

.warningIconFunctionButtons {
    @extend .iconFunctionButtons;
    color: var(--warning);
}

.dangerIconFunctionButtons {
    @extend .iconFunctionButtons;
    color: var(--danger);
}

.successIconFunctionButtons {
    @extend .iconFunctionButtons;
    color: var(--success);
}

.invoiceOptions {
    margin-top:  15px;
}

.quicksearch {
    padding: 5px 5px 0px 5px;
    margin: 10px 0px;
}

.inputField {
    border: solid 1px var(--filterShadow);
}

.detailBlock h5 {
    font-weight: 500;
}