﻿/*Override the max widths to allow for more room*/
@media (min-width: 1200px) {
  .container {
    max-width: none;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: none;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: none;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: none;
  }
}

.detailContainer {
  font-size: 0.8rem;
  margin: 5px;
  padding: 3px;
  color: var(--text);
}

#lineItemSection .col {
  word-break: break-word;
}

#lineItemSection {
  word-break: break-word;
}

.poLabel {
  margin-top: 5px;
  padding-left: 15px;
  font-size: 32px;
  font-weight: 700;
}

.detailRow {
  line-height: 1.1;
}

.detailRow .col {
  width: 50%;
  word-break: break-word;
}

.orderButton {
  margin-bottom: 5px;
  margin-right: 10px;
  margin-top: 5px;
}

.backButtonContainer {
  text-align: right;
}

.deleteWarning {
  border: 2px solid;
  padding: 0px 9px;
  border-radius: 20%;
  margin-left: 10px;
  cursor: pointer;
}

.deleteWarning:hover {
  cursor: pointer;
}


.noteAddNoteButton {
  float: left;
  color: var(--success);
  border: 1px solid;
  padding: 0px 10px;
  border-radius: 0.5rem;
}

.noteAddNoteButton:hover,
.noteEditNoteButton:hover,
.noteEditButton:hover,
.noteDeleteButton:hover {
  cursor: pointer;
  color: var(--themebtnHover);
}

.noteEditNoteButton {
  color: var(--primary);
  margin-left: 7px;
  border: 1px solid;
  padding: 0px 10px;
  border-radius: 0.5rem;
}

.noteBlock {
  display: flex;
}

.noteHeading:after {
  border-bottom: 1px solid var(--primary);
  content: "";
  display: block;
  width: 105%;
}

.noteEditRow {
  margin: 20px 0px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--background);
}

.noteEditButton {
  border: 1px solid var(--success);
  color: var(--success);
  padding: 3px 4px;
  border-radius: 0.3rem;
}

.noteDeleteButton {
  border: 1px solid var(--danger);
  color: var(--danger);
  padding: 3px 4px;
  border-radius: 0.3rem;
}

.noteEditDropdown {
  padding-top: 15px;
}

.PSDButtonBlock {
  float: right;
}

.PSDButton {
  color: var(--primary);
}

.PSDButton:hover {
  cursor: pointer;
  color: var(--themebtnHover);
}

.AcceptOrderModalContentRow {
  border-top: 1px solid var(--text);
  padding: 10px 0px;
  cursor: pointer;
}

.AcceptOrderModalContentRowSelected {
  background-color: var(--themeActiveBlock);
  border-top: 1px solid var(--text);
  padding: 10px 0px;
}

.form-group {
  margin-bottom: 0px;
}

.warningSign {
  color: #f6d73c;
}


.AcceptedOrderBanner {
  color: var(--success);
  font-weight: 600;
  padding-left: 15px;
  text-align: center;
  margin-top: 5px;
}

.RejectedOrderBanner {
  color: var(--danger);
  font-weight: 600;
  padding-left: 15px;
  text-align: center;
  margin-top: 5px;
}

// .OptionsMenuTab {
//   position: absolute;
//   right: 0;
//   z-index: 2;
//   background-color: #fff;
//   width: 7%;
//   height: 100%;
//   color: #000;
//   text-align: center;
// }

.table-divborder {
  border: solid 1px #fff;
  border-radius: 0.25rem;
}

.table {
  margin-bottom: 0px;
}

// Right Menu

.OptionsMenu {
  position: absolute;
  right: 5%;
  z-index: 2;
  background-color: var(--container);
  width: 44.5%;
  height: 10%;
  color: var(----text);
  text-align: center;
  margin: 5px 0px;
  border-radius: .25rem;
}

.OptionsMenuTab {
  margin-top: 5px;
  position: absolute;
  right: 7px;
  z-index: 2;
  background-color: var(--background);
  width: 2%;
  height: 10%;
  color: var(--text);
  text-align: center;
  border: solid 1px var(--text);
  border-radius: .25rem;
  display: flex;
  align-items: flex-start;
  user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
  cursor: pointer;
}


.OptionItem:hover, .OptionsMenuTab:hover {
  cursor: pointer;
  background-color: var(--themeActiveBlock);
}

.OptionItem {
  border: solid 1px var(--primary);
  border-radius: .25rem;
  float: left;
  padding: 14px 10px;
  margin: 0px 5px;
  -webkit-user-select: none; /* Chrome/Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}


.removeTopBorder {
  border-top: none !important;
}

.addBottomBorder {
  border-bottom: solid 1px;
}

.acceptOrderIcons {
  color: var(--warning);
  margin: 0px 5px 0px 5px
  
}